<template>
  <div>
    <b-row>
      <b-col>
        <b-button
          class="mb-1"
          variant="primary"
          @click="openModalFiltro"
        >
          <span class="align-middle">Novo Filtro</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <div class="align-items-center">
                <b-row>
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-warning"
                        rounded
                      >
                        <feather-icon
                          icon="TrendingUpIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.parcelas }}
                        </h5>
                        <small>Operações</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.honorario, true) }}
                        </h5>
                        <small>Total Hon. Variável</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.multa, true) }}
                        </h5>
                        <small>Total Multa</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.voz, true) }}
                        </h5>
                        <small>Remuneração</small>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.realizado, true) }}
                        </h5>
                        <small>Total Realizado</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.comissao, true) }}
                        </h5>
                        <small>Total Hon. Fixo</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.juros, true) }}
                        </h5>
                        <small>Total Juros</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-danger"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.repasse, true) }}
                        </h5>
                        <small>Total Repasse</small>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col md="3">
                    <b-button
                      class="mb-1"
                      variant="outline-warning"
                      :disabled="!items.length > 0"
                      @click="enviarPlanilha"
                    >
                      <span class="align-middle">Enviar por Email</span>
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      class="mb-1"
                      variant="outline-warning"
                      :disabled="!items.length > 0"
                      @click="exportXlsx"
                    >
                      <span class="align-middle">Exportar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <b-col
              cols="21"
              xl="4"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Credor</span>
                  </th>
                  <td class="pb-50">
                    <b-badge variant="primary">
                      {{ filtro.credor ? filtro.credor.nome : '' }}
                    </b-badge>
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Data Inicial</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filtro.data_inicial }}
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Data Final</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filtro.data_final }}
                  </td>
                </tr>

              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mb-1">
            <b-col
              md="3"
            >
              <label for="">Filtro</label>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Digite para pesquisar"
              />
            </b-col>
          </b-row>
          <b-table
            hover
            striped
            responsive
            fixed
            :fields="fields"
            :items="items"
            :per-page="itemPerPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'origem'
                      ? '400px'
                      : '180px',
                }"
              >
            </template>
            <template #cell(cliente)="data">
              {{ data.item.cliente ? fisrtLastName(data.item.cliente) : '' }}
            </template>

            <template #cell(documento)="data">
              {{ data.item.documento ? cpfLabel(data.item.documento) : '' }}
            </template>

            <template #cell(vencimento)="data">
              {{ data.item.vencimento ? americaDate(data.item.vencimento) : '' }}
            </template>

            <template #cell(vencimento_original)="data">
              {{ data.item.vencimento_original ? americaDate(data.item.vencimento_original) : '' }}
            </template>

            <template #cell(recebimento)="data">
              {{ data.item.recebimento ? americaDate(data.item.recebimento) : '' }}
            </template>

            <template #cell(valor_nominal)="data">
              {{ data.item.valor_nominal ? `R$ ${parseFloat(data.item.valor_nominal).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_juros)="data">
              {{ data.item.valor_juros ? `R$ ${parseFloat(data.item.valor_juros).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_multa)="data">
              {{ data.item.valor_multa ? `R$ ${parseFloat(data.item.valor_multa).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_honorario)="data">
              {{ data.item.valor_honorario ? `R$ ${parseFloat(data.item.valor_honorario).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_comissao)="data">
              {{ data.item.valor_comissao ? `R$ ${parseFloat(data.item.valor_comissao).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_baixado)="data">
              {{ data.item.valor_baixado ? `R$ ${parseFloat(data.item.valor_baixado).toFixed(2)}` : 'R$ 0,00' }}
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="itemPerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      id="filtro"
      ref="filtro"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-row class="mb-1">
        <b-col md="12">
          <label for="">Selecione o credor</label>
          <v-select
            v-model="filtro.credor"
            :options="credores"
            :close-on-select="false"
            label="nome"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="">Data Início</label>
          <b-input-group class="mb-1">
            <the-mask
              v-model="filtro.data_inicial"
              autocomplete="off"
              :mask="['##/##/####']"
              :masked="true"
              class="form-control"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.data_inicial_en"
                show-decade-nav
                button-only
                right
                size="sm"
                locale="en-US"
                aria-controls="example-input"
                @input="changeDataFormat($event, 'data_inicial')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <label for="">Data Final</label>
          <b-input-group class="mb-1">
            <the-mask
              v-model="filtro.data_final"
              autocomplete="off"
              :mask="['##/##/####']"
              :masked="true"
              class="form-control"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.data_final_en"
                show-decade-nav
                button-only
                right
                size="sm"
                locale="en-US"
                aria-controls="example-input"
                @input="changeDataFormat($event, 'data_final')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <template #modal-footer="{cancel}">
        <div class="w-100">
          <b-button
            variant="outline-danger"
            class="float-right ml-1"
            @click="cancel()"
          >
            Cancelar
          </b-button>
          <b-button
            variant="warning"
            class="float-right"
            :disabled="validateForm"
            @click="getFilter"
          >
            Filtrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
  BCard,
  BAvatar,
  BBadge,
  BTable,
  BPagination,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { TheMask } from 'vue-the-mask'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { saveAs } from 'file-saver'
import api from '@/../axios-auth'
// eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars
const ExcelJS = require('exceljs')

export default {
  components: {
    BRow,
    BCol,
    BModal,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
    TheMask,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    BTable,
    BPagination,
  },
  data() {
    return {
      credores: [],
      items: [],
      filter: null,
      filterOn: [],
      itemPerPage: 20,
      currentPage: 1,
      totalRows: 1,
      data: {
        parcelas: 0,
        realizado: 0,
        voz: 0,
        honorario: 0,
        comissao: 0,
        repasse: 0,
        juros: 0,
        multa: 0,
      },
      filtro: {
        credor: null,

        data_inicial: '',
        data_inicial_en: '',

        data_final: '',
        data_final_en: '',
      },
      fields: [
        { key: 'cliente', label: 'cliente' },
        { key: 'documento', label: 'CPF/CNPJ' },
        { key: 'origem', label: 'origem' },
        { key: 'numero_operacao', label: 'operação' },
        { key: 'parcela', label: 'parcela' },
        { key: 'vencimento', label: 'vencimento' },
        { key: 'vencimento_original', label: 'Venc. Original' },
        { key: 'recebimento', label: 'recebimento' },
        { key: 'valor_nominal', label: 'Vl. Nominal' },
        { key: 'juros', label: 'juros' },
        { key: 'multa', label: 'multa' },
        { key: 'honorarios', label: 'Hon. Variável' },
        { key: 'comissao', label: 'Hon. Fixo' },
        { key: 'valor_baixado', label: 'valor baixado' },
      ],
    }
  },
  computed: {
    validateForm() {
      const { credor, data_inicial: dataInicial, data_final: dataFinal } = this.filtro
      const checkDataInicial = dataInicial ? dataInicial.length === 10 : null
      const checkDataFinal = dataFinal ? dataFinal.length === 10 : null
      if (credor && checkDataInicial && checkDataFinal) {
        return false
      }
      return true
    },
  },
  watch: {
    'filtro.data_inicial': {
      handler(n) {
        if (n.length === 10) {
          const splited = n.split('/')
          const newDate = `${splited[2]}-${splited[1]}-${splited[0]}`
          if (this.filtro.data_inicial_en !== newDate) {
            this.filtro.data_inicial_en = newDate
          }
        }
      },
      deep: true,
    },
    'filtro.data_final': {
      handler(n) {
        if (n.length === 10) {
          const splited = n.split('/')
          const newDate = `${splited[2]}-${splited[1]}-${splited[0]}`
          if (this.filtro.data_final_en !== newDate) {
            this.filtro.data_final_en = newDate
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.getCredores()
  },
  mounted() {
  },
  methods: {
    async getCredores() {
      const credor = JSON.parse(localStorage.getItem('credores'))
      credor.map(item => {
        this.credores.push({
          id: item.id,
          nome: item.nome,
        })
      })
    },
    async openModalFiltro() {
      this.credores

      this.$refs.filtro.show()
    },

    changeDataFormat(item, pos) {
      const splited = item.split('-')
      const newDate = `${splited[2]}/${splited[1]}/${splited[0]}`
      if (!this.filtro[pos] !== newDate) {
        this.filtro[pos] = newDate
      }
    },

    getFilter() {
      const {
        credor,
        // eslint-disable-next-line camelcase
        data_inicial_en: data_inicio,
        // eslint-disable-next-line camelcase
        data_final_en: data_final,
      } = this.filtro
      const body = {
        credor_id: [credor.id],
        data_recebimento_inicio: data_inicio,
        data_recebimento_final: data_final,
      }
      api.post('api/v1/repasses/filtrar', body, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(async result => {
        this.items = result.data.dados
        this.totalRows = result.data.dados.length
        await this.setData()
        this.$refs.filtro.hide()
      })
    },

    setData() {
      const { items } = this
      const data = {
        parcelas: 0,
        realizado: 0,
        voz: 0,
        honorario: 0,
        comissao: 0,
        repasse: 0,
        juros: 0,
        multa: 0,
      }
      if (items) {
        data.parcelas = items.length
        items.map(item => {
          !item.valor_comissao ? item.valor_comissao = 0 : null
          !item.valor_honorario ? item.valor_honorario = 0 : null

          const voz = /* parseFloat(parseFloat(item.valor_comissao).toFixed(2)) */0 + parseFloat(parseFloat(item.valor_honorario).toFixed(2))
          data.realizado += parseFloat(parseFloat(item.realizado).toFixed(2))
          data.honorario += parseFloat(parseFloat(item.honorarios).toFixed(2))
          // data.comissao += parseFloat(parseFloat(item.valor_comissao).toFixed(2))
          data.repasse += (parseFloat(parseFloat(item.valor_repasse).toFixed(2)) - parseFloat(parseFloat(item.honorarios).toFixed(2)))
          data.voz += voz
          data.juros += parseFloat(parseFloat(item.juros).toFixed(2))
          data.multa += parseFloat(parseFloat(item.multa).toFixed(2))
        })
      }
      data.realizado = parseFloat(data.realizado.toFixed(2))
      data.voz = parseFloat(data.voz.toFixed(2))
      data.repasse = parseFloat(data.repasse.toFixed(2))
      this.data = data
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async enviarPlanilha() {
      const file = await this.getXlsx()
      if (file) {
        const { credor, data_final } = this.filtro
        const emailBody = new FormData()
        emailBody.append('planilha', file)
        // eslint-disable-next-line camelcase
        emailBody.append('subject', `${credor.nome} - RECEBIMENTOS ${data_final}`)
        emailBody.append('to', 'rafhaelgigalucas@gmail.com')
        emailBody.append('reason', 'repasse_planilha')
        emailBody.append('date', '17/03/2022')

        api.post('http://192.168.254.216:3333/repasse/planilha', emailBody).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: '👋 Email Enviado',
              variant: 'success',
            },
          }, { position: 'top-center' })
        })
      }
    },
    async exportXlsx() {
      const file = await this.getXlsx()
      if (file) {
        saveAs(file)
      }
    },
    // eslint-disable-next-line consistent-return
    async getXlsx() {
      const { credor, data_final } = this.filtro
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'VOZ - COBRANÇA'
      workbook.created = new Date()

      const worksheet = workbook.addWorksheet('Parcelas')
      const worksheet2 = workbook.addWorksheet('Operações')
      const worksheet3 = workbook.addWorksheet('Acordos')
      let parcelas = this.items.map(item => item.parcela_id)
      parcelas = [...new Set(parcelas)]
      worksheet.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'CPF/CNPJ' },
        { header: 'Situação', key: 'situacao' },
        { header: 'Acordo' },
        { header: 'Vencimento' },
        { header: 'Pagamento' },
        { header: 'Parcela' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'juros' },
        { header: 'Multa', key: 'multa' },
        { header: 'Desconto', key: 'desconto' },
        { header: 'Hon. Variável', key: 'honorarios' },
        { header: 'Realizado', key: 'realizado' },
        // { header: 'Comissão', key: 'comissao' },
        { header: 'Repasse', key: 'repasse' },
        { header: 'Forma' },
      ]
      worksheet2.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'Aluno' },
        { header: 'CPF/CNPJ' },
        { header: 'Situação', key: 'situacao' },
        { header: 'Acordo' },
        { header: 'Nº Operação' },
        { header: 'Origem' },
        { header: '' },
        { header: 'Pagamento' },
        { header: 'Parcela' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'juros' },
        { header: 'Multa', key: 'multa' },
        { header: 'Desconto', key: 'desconto' },
        { header: 'Hon. Variável', key: 'honorarios' },
        { header: 'Realizado', key: 'realizado' },
        // { header: 'Comissão', key: 'comissao' },
        { header: 'Repasse', key: 'repasse' },
        { header: 'Forma' },
      ]
      worksheet3.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'CPF/CNPJ' },
        { header: 'Acordo' },
        { header: 'Condição' },
        { header: 'Qtd. Parcelas' },
        { header: 'Qtd. Operações' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'juros' },
        { header: 'Multa', key: 'multa' },
        { header: 'Desconto', key: 'desconto' },
        { header: 'Hon. Variável', key: 'honorarios' },
        { header: 'Realizado', key: 'realizado' },
        { header: 'Detalhamento', key: 'detalhamento' },
      ]

      // const totais = {
      //   principal: 0,
      //   juros: 0,
      //   multa: 0,
      //   correcao: 0,
      //   desconto: 0,
      //   honorarios: 0,
      //   realizado: 0,
      //   taxa_cartao: 0,
      //   comissao: 0,
      //   repasse: 0,
      //   valor_repasse: 0,
      //   negociado: 0,
      // }
      const wk1Columns = [
        'principal',
        'juros',
        'multa',
        'desconto',
        'honorarios',
        'realizado',
        // 'comissao',
        'repasse',
      ]

      const wk2Columns = [
        'principal',
        'juros',
        'multa',
        'desconto',
        'honorarios',
        'realizado',
        // 'comissao',
        'repasse',
      ]

      const wk3Columns = [
        'principal',
        'juros',
        'multa',
        'desconto',
        'honorarios',
        'realizado',
      ]

      const totais = {
        wk1: {},
        wk2: {},
        wk3: {},
      }

      parcelas.map(p => {
        const filtered = this.items.filter(e => e.parcela_id === p)
        const acordosDone = []
        const ids = filtered.map(po => {
          worksheet2.addRow([
            po.instituicao,
            po.cliente,
            po.aluno,
            po.documento,
            po.situacao,
            po.numero_acordo,
            po.numero_operacao,
            po.origem,
            this.americaDate(po.vencimento_original),
            this.americaDate(po.recebimento),
            po.parcela,
            parseFloat(po.principal),
            parseFloat(po.juros),
            parseFloat(po.multa),
            parseFloat(po.desconto),
            parseFloat(po.honorarios),
            parseFloat(po.realizado),
            // parseFloat(po.comissao),
            parseFloat(po.repasse),
            po.forma,
          ])
          // totais.wk2[] = {}
          wk2Columns.map(w => {
            totais.wk2[w] ? totais.wk2[w] += parseFloat(po[w]) : totais.wk2[w] = parseFloat(po[w])
          })
          if (!acordosDone.includes(po.numero_acordo)) {
            const qntOp = parseInt(po.parcela.split('/')[1])
            const acordoProp = {
              principal: 0,
              juros: 0,
              multa: 0,
              desconto: 0,
              honorarios: 0,
              realizado: 0,
            }
            const acordosPo = filtered.filter(e => e.numero_acordo === po.numero_acordo)
            acordosPo.map(i => {
              Object.keys(acordoProp).map(key => {
                if (acordosPo.length > 1) {
                  acordoProp[key] += parseFloat(i[key])
                } else {
                  acordoProp[key] = parseFloat(po[key])
                }
              })
            })
            worksheet3.addRow([
              po.instituicao,
              po.cliente,
              po.documento,
              po.numero_acordo,
              parseInt(po.parcela.split('/')[1]) > 1 ? 'PARCELADO' : 'AVISTA',
              qntOp,
              filtered.length,
              parseFloat(acordoProp.principal),
              parseFloat(acordoProp.juros),
              parseFloat(acordoProp.multa),
              parseFloat(acordoProp.desconto),
              parseFloat(acordoProp.honorarios),
              parseFloat(acordoProp.realizado),
              `1ª parcela no valor de R$ ${this.valorBr(parseFloat(acordoProp.realizado))}`,
            ])
            wk3Columns.map(w => {
              totais.wk3[w] ? totais.wk3[w] += parseFloat(acordoProp[w]) : totais.wk3[w] = parseFloat(acordoProp[w])
            })
            acordosDone.push(po.numero_acordo)
          }
          return po
        })
        const parc = {
          instituicao: '',
          cliente: '',
          documento: '',
          situacao: '',
          numero_acordo: '',
          vencimento: '',
          recebimento: '',
          parcela: '',
          principal: 0,
          juros: 0,
          multa: 0,
          desconto: 0,
          honorarios: 0,
          realizado: 0,
          // comissao: 0,
          repasse: 0,
          forma: '',
        }
        ids.map(i => {
          parc.instituicao = i.instituicao
          parc.cliente = i.cliente
          parc.documento = i.documento
          parc.situacao = i.situacao
          parc.numero_acordo = i.numero_acordo
          parc.vencimento = this.americaDate(i.vencimento) || ''
          parc.recebimento = this.americaDate(i.recebimento) || ''
          parc.parcela = i.parcela
          parc.principal += parseFloat(i.principal)
          parc.juros += parseFloat(i.juros)
          parc.multa += parseFloat(i.multa)
          parc.desconto += parseFloat(i.desconto)
          parc.honorarios += parseFloat(i.honorarios)
          parc.realizado += parseFloat(i.realizado)
          // parc.comissao += parseFloat(i.comissao)
          parc.repasse += parseFloat(i.repasse)
          parc.forma = i.forma

          wk1Columns.map(w => {
            totais.wk1[w] ? totais.wk1[w] += parseFloat(i[w]) : totais.wk1[w] = parseFloat(i[w])
          })
        })
        worksheet.addRow([
          parc.instituicao,
          parc.cliente,
          parc.documento,
          parc.situacao,
          parc.numero_acordo,
          parc.vencimento ? this.americaDate(parc.vencimento) : '',
          parc.recebimento ? this.americaDate(parc.recebimento) : '',
          parc.parcela,
          parseFloat(parc.principal.toFixed(2)),
          parseFloat(parc.juros.toFixed(2)),
          parseFloat(parc.multa.toFixed(2)),
          parseFloat(parc.desconto.toFixed(2)),
          parseFloat(parc.honorarios.toFixed(2)),
          parseFloat(parc.realizado.toFixed(2)),
          // parseFloat(parc.comissao.toFixed(2)),
          parseFloat(parc.repasse.toFixed(2)),
          parc.forma,
        ])
      })
      if (this.filtro.credor.id !== parseInt(process.env.VUE_APP_ROOT_EDUCA_MAIS)) {
        // worksheet2.spliceColumns(5, 5)
        worksheet2.spliceColumns(5, 1)
        worksheet3.spliceColumns(14, 1)
        worksheet.spliceColumns(4, 1)
      }
      const totalRowWk2 = worksheet2.addRow({
        principal: totais.wk2.principal,
        juros: totais.wk2.juros,
        multa: totais.wk2.multa,
        desconto: totais.wk2.desconto,
        honorarios: totais.wk2.honorarios,
        realizado: totais.wk2.realizado,
        repasse: totais.wk2.repasse,
      })
      totalRowWk2.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk2.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
      })

      const totalRowWk1 = worksheet.addRow({
        principal: totais.wk1.principal,
        juros: totais.wk1.juros,
        multa: totais.wk1.multa,
        desconto: totais.wk1.desconto,
        honorarios: totais.wk1.honorarios,
        realizado: totais.wk1.realizado,
        repasse: totais.wk1.repasse,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
      })

      const totalRowWk3 = worksheet3.addRow({
        principal: totais.wk3.principal,
        juros: totais.wk3.juros,
        multa: totais.wk3.multa,
        desconto: totais.wk3.desconto,
        honorarios: totais.wk3.honorarios,
        realizado: totais.wk3.realizado,
      })
      totalRowWk3.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk3.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
      })

      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      wk2Columns.map(key => {
        worksheet2.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      wk3Columns.map(key => {
        worksheet3.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'ffffff' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet2.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'ffffff' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet3.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'ffffff' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: false }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })
      worksheet2.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: false }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })
      worksheet3.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: false }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], `${credor.nome} - RECEBIMENTOS ${data_final}`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
  },
}
</script>
